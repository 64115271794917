<template>
    <div>
        <a-modal title="显示到期" :width="540" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    确认
                </a-button>
            </template>
            <div>
                <a-spin :spinning="loading">
                    <a-form-model-item label="是否显示" required>
                        <a-radio-group name="radioGroup" v-model="time_state">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="2">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-spin>

            </div>
        </a-modal>
    </div>
</template>

<script>

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        shop_id: {
            type: Number,
            default: 0,
        },
        time_state: {
            type: String,
            default: 0,
        },
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            form: {
                is_tui: '',
            }
        }
    },
    created() {
        // this.loaddata();
    },
    methods: {

        // loaddata(){
        // 	if(this.loading==true) return;
        // 	this.loading=true;
        // 	this.$http.api('platform/admin/shop_display',{
        // 		shop_id:this.shop_id,
        // 	}).then(res=>{
        // 		this.form=res.detail;
        // 		this.loading=false;
        // 	}).catch(res=>{
        // 		console.log(res);
        // 		this.loading=false;
        // 		this.$emit("cancel");
        // 	})
        // },

        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },

        /**
         * 提交数据
         */
        onSubmit() {
            if (this.confirmLoading == true) return;
            this.confirmLoading = true;
            this.$http.api('api/store/mature', {
                id: this.shop_id,
                time_state: this.time_state,
            }).then(res => {
                this.confirmLoading = false;
                this.$message.success('设置成功', 1, () => {
                    this.$emit("ok");
                })
            }).catch(res => {
                this.confirmLoading = false;
                console.log(res);
            })

        },

    }
}
</script>

<style>
.renew-miniapp-inpt .ant-input {
    width: 340px;
}
</style>
