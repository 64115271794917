<template>
    <div>
        <a-modal title="指定客服" :width="700" :visible="visible" @cancel="handleCancel" :footer="null">
            <div>
                <div class="wxb-table-gray">
                    <a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">
                        <div slot="or_code" slot-scope="or_code,record">
                            <img v-if="or_code!=null&&or_code" :src="BASE_IMG_URL+or_code" style="width: 40px;height: 40px;" />
                            <img v-else src="../../../../../assets/image/kefu.png" style="width: 40px;height: 40px;" />
                        </div>

                        <template slot="action" slot-scope="text,record">
                            <a-button type="primary" @click="chooseIt(record)">选择</a-button>
                        </template>
                    </a-table>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { listMixin } from '../../../../../common/mixin/list.js';
export default {
    mixins: [listMixin],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        customer_service_id: {
            type: String,
            default: 0,
        },
        shop_id: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            BASE_IMG_URL:window.global_config.BASE_IMG_URL,
            loading: false,
            columns: [
                { title: 'ID编号', dataIndex: 'id', align: 'center', ellipsis: true },
                { title: '客服名称', dataIndex: 'name', align: 'center', ellipsis: true },
                { title: '客服二维码', dataIndex: 'or_code', align: 'center', scopedSlots: { customRender: 'or_code' } },
                { title: '客服手机号', dataIndex: 'mobile', align: 'center', ellipsis: true },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },

            ],
            datas: [],
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
        }
    },
    created() {

    },
    methods: {

        getLists() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/store/CustomerList', {
                // customer_service_id: this.customer_service_id,
                limit: this.pagination.pageSize,
                page: this.pagination.current,
            }).then(res => {
                this.pagination.total = res.data.total;
                this.datas = res.data.data;
                this.loading = false;
            }).catch(res => {
                console.log(res);
            }).finally(() => {
                this.loading = false;
            })
        },

        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },

        chooseIt(record) {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/store/appoint', {
                id: this.shop_id,
                staff_user_id: record.id,
            }).then(res => {
                this.$message.success('指定成功', 1, () => {
                    this.loading = false;
                    this.$emit('ok');
                })
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },


        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },

    }
}
</script>

<style>
</style>
