<template>
    <div>
        <a-modal title="支付设置" :width="700" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    确认配置
                </a-button>
            </template>
            <div>
                <a-spin :spinning="loading">
                    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19}">
                        <a-form-model-item label="小程序APPID" help="小程序APPID">
                            <a-input style="width: 300px;" v-model="form.applet_id" placeholder="请输入小程序APPID" />
                            <span> (非独立小程序可不填)</span>
                        </a-form-model-item>
                        <a-form-model-item label="小程序APPSecret" help="小程序APPSecret">
                            <a-input style="width: 300px;" v-model="form.applet_key" placeholder="请输入小程序APPSecret" />
                            <span> (非独立小程序可不填)</span>
                        </a-form-model-item>
                        <a-form-model-item label="支付类型" help="服务商类型必须是平台协助开通的支付">
                            <a-select style="width: 300px" v-model="form.payment_type">
                                <a-select-option :value="1">
                                    直连商户支付
                                </a-select-option>
                                <a-select-option :value="2">
                                    服务商支付
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item label="商户ID" help="微信支付商户后台的商户ID号">
                            <a-input style="width: 300px;" v-model="form.wx_sh_id" placeholder="请输入微信支付商户ID" />
                        </a-form-model-item>

                        <a-form-model-item v-if="form.payment_type == 1" label="API秘钥" help="微信支付商户后台的32位的商户秘钥,V3的API秘钥请设置同样的">
                            <a-input style="width: 300px;" v-model="form.wx_sh_API" placeholder="请输入API秘钥" />
                        </a-form-model-item>
                        <a-form-model-item v-if="form.payment_type == 1" label="证书.cert" help="微信支付证书的apiclient_cert.pem文件里面的内容">
                            <a-textarea style="width: 500px;" v-model="form.wx_sh_cert" placeholder="请粘贴apiclient_cert.pem文件内容" :maxLength="50000" :rows="4" />
                        </a-form-model-item>
                        <a-form-model-item v-if="form.payment_type == 1" label="证书.KEY" help="微信支付证书的apiclient_key.pem文件里面的内容">
                            <a-textarea style="width: 500px;" v-model="form.wx_sh_KEY" placeholder="请粘贴apiclient_key.pem文件内容" :maxLength="50000" :rows="4" />
                        </a-form-model-item>

                        <!-- <a-form-model-item v-if="form.type == 0" label="商户ID" help="微信支付商户后台的商户ID号">
							 <a-input style="width: 300px;" v-model="form.mach_id"  placeholder="请输入微信支付商户ID" />
						</a-form-model-item>
						<a-form-model-item v-if="form.type == 0" label="API秘钥" help="微信支付商户后台的32位的商户秘钥,V3的API秘钥请设置同样的">
							 <a-input style="width: 300px;" v-model="form.api_auth"  placeholder="请输入微信支付API秘钥" />
						</a-form-model-item>
						<a-form-model-item  v-if="form.type == 0"  label="支付证书cert" help="微信支付证书的apiclient_cert.pem文件里面的内容">
							<a-textarea style="width: 500px;" v-model="form.api_cert" placeholder="请粘贴apiclient_cert.pem文件内容" :maxLength="50000"  :rows="8" />
						</a-form-model-item>
						<a-form-model-item  v-if="form.type == 0"  label="支付证书key" help="微信支付证书的apiclient_key.pem文件里面的内容">
							<a-textarea style="width: 500px;" v-model="form.api_key" placeholder="请粘贴apiclient_key.pem文件内容" :maxLength="50000"  :rows="8" />
						</a-form-model-item> -->
                    </a-form-model>
                </a-spin>
            </div>
        </a-modal>
    </div>
</template>

<script>

export default {

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        shop_id: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            form: {
                applet_id: '',
                applet_key: '',
                payment_type: 1,
                wx_sh_id: '',
                wx_sh_API: '',
                wx_sh_cert: '',
                wx_sh_KEY: '',
            }
        }
    },
    created() {
        this.loaddata();
    },
    methods: {
        loaddata() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/store/listInfo', {
                shop_id: this.shop_id,
            }).then(res => {
                if (res.data.info) {
                    this.form.applet_id = res.data.info.applet_id
                    this.form.applet_key = res.data.info.applet_key
                    this.form.payment_type = res.data.info.payment_type
                    this.form.wx_sh_id = res.data.info.wx_sh_data.wx_sh_id
                    this.form.wx_sh_API = res.data.info.wx_sh_data.wx_sh_API
                    this.form.wx_sh_cert = res.data.info.wx_sh_data.wx_sh_cert
                    this.form.wx_sh_KEY = res.data.info.wx_sh_data.wx_sh_KEY
                }
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },

        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },

        onSubmit() {
            if (this.confirmLoading == true) return;
            this.confirmLoading = true;
            this.$http.api('api/store/payment', {
                id: this.shop_id,
                applet_id: this.form.applet_id,
                applet_key: this.form.applet_key,
                payment_type: this.form.payment_type,
                wx_sh_id: this.form.wx_sh_id,
                wx_sh_API: this.form.wx_sh_API,
                wx_sh_cert: this.form.wx_sh_cert,
                wx_sh_KEY: this.form.wx_sh_KEY,
            }).then(res => {
                this.$message.success('保存成功', 1, () => {
                    this.confirmLoading = false;
                    this.$emit('ok');
                });
            }).catch(res => {
                this.confirmLoading = false;

            });
        },

    }
}
</script>

<style>
</style>
